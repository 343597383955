import getOffsetParent from './getOffsetParent';
import getScrollParent from './getScrollParent';
import getOffsetRect from './getOffsetRect';
import getPosition from './getPosition';
import getOffsetRectRelativeToCustomParent from './getOffsetRectRelativeToCustomParent';
import getTotalScroll from './getTotalScroll';
import isFixed from './isFixed';
import getWindowSizes from './getWindowSizes';

/**
 * Computed the boundaries limits and return them
 * @method
 * @memberof Popper.Utils
 * @param {Object} data - Object containing the property "offsets" generated by `_getOffsets`
 * @param {Number} padding - Boundaries padding
 * @param {Element} boundariesElement - Element used to define the boundaries
 * @returns {Object} Coordinates of the boundaries
 */
export default function getBoundaries(popper, padding, boundariesElement) {
    // NOTE: 1 DOM access here
    let boundaries = { top: 0, left: 0 };
    const offsetParent = getOffsetParent(popper);

    // Handle viewport case
    if (boundariesElement === 'viewport') {
        const { left, top } = getOffsetRect(offsetParent);
        const { clientWidth: width, clientHeight: height } = window.document.documentElement;

        if (getPosition(popper) === 'fixed') {
            boundaries.right = width;
            boundaries.bottom = height;
        } else {
            const scrollLeft = getTotalScroll(popper, 'left');
            const scrollTop = getTotalScroll(popper, 'top');

            boundaries = {
                top: 0 - top,
                right: width - left + scrollLeft,
                bottom: height - top + scrollTop,
                left: 0 - left,
            };
        }
    }
    // Handle other cases based on DOM element used as boundaries
    else {
        let boundariesNode;
        if (boundariesElement === 'scrollParent') {
            boundariesNode = getScrollParent(popper);
        } else if (boundariesElement === 'window') {
            boundariesNode = window.document.body;
        } else {
            boundariesNode = boundariesElement;
        }

        // In case of BODY, we need a different computation
        if (boundariesNode.nodeName === 'BODY') {
            const { height, width } = getWindowSizes();
            boundaries.right = width;
            boundaries.bottom = height;
        }
        // for all the other DOM elements, this one is good
        else {
            boundaries = getOffsetRectRelativeToCustomParent(boundariesNode, offsetParent, isFixed(popper));
        }
    }

    // Add paddings
    boundaries.left += padding;
    boundaries.top += padding;
    boundaries.right -= padding;
    boundaries.bottom -= padding;

    return boundaries;
}
